import '../scss/app.scss'; // Importing SCSS file

// Send a message to the iframe to perform an action
document.addEventListener('DOMContentLoaded', function() {
    let iframe = [...document.querySelectorAll('iframe')].filter(iframe => iframe.src === 'https://form.jotform.com/form/241566020642045')[0];
    if (iframe) {
        iframe.onload = function() {
            iframe.contentWindow.postMessage({ action: 'modifySelect' }, '*');
        };
    }
});

// Listen for messages from the iframe
window.addEventListener('message', function(event) {
    if (event.origin !== 'https://form.jotform.com') return; // Ensure the message is from the correct origin

    // Handle the response from the iframe if needed
    console.log(event.data);
});
